type cbor = string;
type hex = string;
type Paginate = {
  page: number;
  limit: number;
};

type CoseSign1 = {
  signature: hex;
  key: hex;
};

export type Cardano = {
  getNetworkId(): Promise<number>;
  getUtxos(amount?: cbor, paginate?: Paginate): Promise<cbor[] | undefined>;
  getBalance(): Promise<cbor>;
  getUsedAddresses(paginate?: Paginate): Promise<cbor[]>;
  getUnusedAddresses(): Promise<cbor[]>;

  getChangeAddress(): Promise<cbor>;
  getRewardAddresses(): Promise<cbor[]>;
  signTx(tx: cbor, partialSign: boolean): Promise<cbor>;

  signData(addr: cbor, sigStructure: cbor): Promise<CoseSign1>;
  submitTx(tx: cbor): Promise<hex>;

  getCollateral?: () => Promise<cbor[] | undefined>;

  // NAMI Experimental for now.
  experimental?: {
    on: (
      event: 'networkChange' | 'accountChange',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler: (x: any) => void
    ) => void;
    off: (
      event: 'networkChange' | 'accountChange',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler: (x: any) => void
    ) => void;
    getCollateral: () => Promise<cbor[] | undefined>;
  };
};

export type CardanoInitial = {
  enable: () => Promise<Cardano>;
  isEnabled: () => Promise<boolean>;
  apiVersion: string;
  name: string;
  internalName: string;
  icon: string;
};
// export const SUPPORTED_WALLETS = [
//   'nami',
//   'flint',
//   'eternl',
//   'typhoncip30',
// ] as const;

export const SUPPORTED_WALLETS = [
  'nami',
  'lace',
  'flint',
  'typhoncip30',
  'eternl',
  'gerowallet',
  'nufi',
  'yoroi',
  'vespr',
] as const;
// export const SUPPORTED_WALLETS_META = [
//   {
//     name: 'Nami',
//     internalName: 'nami',
//     icon: '/img/nami.svg',
//     mobileSupported: false,
//     install:
//       'https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo?hl=en-GB',
//   },
//   {
//     name: 'Typhon',
//     internalName: 'typhoncip30',
//     icon: '/img/typhon.svg',
//     mobileSupported: false,
//     install:
//       'https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh',
//   },
//   {
//     name: 'Flint',
//     internalName: 'flint',
//     icon: '/img/flint.webp',
//     deeplinkURL:
//       'https://flint-wallet.app.link/browse?dappUrl=https%3A%2F%2Fdropspot.io',
//     mobileSupported: true,
//     install:
//       'https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj',
//   },
//   {
//     name: 'eternl',
//     internalName: 'Eternal Wallet',
//     icon: '/img/ccv.webp',
//     mobileSupported: true,
//     install:
//       'https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka',
//   },
// ];
export const SUPPORTED_WALLETS_META = [
  {
    name: 'Nami',
    addressType: 'PAYMENT',
    internalName: 'nami',
    icon: '/img/nami.svg',
    mobileSupported: false,
    install:
      'https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo?hl=en-GB',
  },
  {
    name: 'Lace',
    addressType: 'PAYMENT',
    internalName: 'lace',
    icon: '/img/lace.jpeg',
    mobileSupported: false,
    install: 'https://chrome.google.com/webstore/search/lace',
  },
  {
    name: 'Typhon',
    addressType: 'PAYMENT',
    internalName: 'typhoncip30',
    icon: '/img/typhon.svg',
    mobileSupported: false,
    install:
      'https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh',
  },
  {
    name: 'Flint',
    addressType: 'PAYMENT',
    internalName: 'flint',
    icon: '/img/flint.webp',
    deeplinkURL:
      'https://flint-wallet.app.link/browse?dappUrl=https%3A%2F%2Fdropspot.io',
    mobileSupported: true,
    install:
      'https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj',
  },
  {
    name: 'Eternl',
    addressType: 'REWARD',
    internalName: 'eternl',
    icon: 'https://eternl.io/icons/favicon-128x128.png',
    mobileSupported: false,
    install:
      'https://chrome.google.com/webstore/detail/kmhcihpebfmpgmihbkipmjlmmioameka',
  },
  {
    name: 'Gero',
    addressType: 'PAYMENT',
    internalName: 'gerowallet',
    icon: '/img/gerowallet.svg',
    mobileSupported: false,
    install:
      'https://chrome.google.com/webstore/detail/gerowallet/bgpipimickeadkjlklgciifhnalhdjhe',
  },
  {
    name: 'Nufi',
    addressType: 'PAYMENT',
    internalName: 'nufi',
    icon: '/img/Nufi.svg',
    mobileSupported: false,
    install:
      'https://chrome.google.com/webstore/detail/nufi/gpnihlnnodeiiaakbikldcihojploeca',
  },
  {
    name: 'Yoroi',
    addressType: 'PAYMENT',
    internalName: 'yoroi',
    icon: '/img/yoroi.svg',
    mobileSupported: false,
    install:
      'https://chrome.google.com/webstore/detail/yoroi/ffnbelfdoeiohenkjibnmadjiehjhajb',
  },
  {
    name: 'Vespr',
    addressType: 'PAYMENT',
    internalName: 'vespr',
    icon: '/img/vespr.png',
    mobileSupported: true,
    install: 'https://www.vespr.xyz/#/',
  },
];

export type SUPPORTED_WALLETS_TYPE = typeof SUPPORTED_WALLETS[number];
declare global {
  interface Window {
    //@ts-expect-error: As I am bringing in Lucid we are both defining the window.cardano object.
    cardano?: { [key in SUPPORTED_WALLETS_TYPE]: CardanoInitial };
  }
  interface Process {
    browser?: boolean;
  }
}
