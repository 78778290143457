import {
  Cardano,
  SUPPORTED_WALLETS,
  CardanoInitial,
  SUPPORTED_WALLETS_META,
} from '#types/cardano';
import { useState } from 'react';
import Web3Token from 'web3-cardano-token/dist/browser';
import getAbsoluteURL from '#lib/getAbsoluteUrl';
import Firebase from '#lib/firebase';
import { NativeAsset } from '#types/index';
import constants from '#lib/constants';
import { create, StateCreator } from 'zustand';
import produce, { enableMapSet } from 'immer';
import {
  Address,
  CborData,
  hexToBytes,
  IntData,
  ListData,
  StakeAddress,
} from '@hyperionbt/helios';
import { devtools } from 'zustand/middleware';
import { trpcClient } from '../../pages/_app';
import { BalanceListener } from './BalanceListener';
import firebase from 'firebase';

enableMapSet();

type WalletObjProps = {
  adaBalance: string;
  adaBalanceNumber: number;
  walletAddress: string;
  stakeAddress: string;
  adaHandle: string;
  assets?: {
    assets: NativeAsset[];
    balance: string;
  };
  wallet: CardanoWalletExtended;
};

export type WalletContextProps = {
  connecting: boolean;
  wallet?: CardanoWalletExtended;
  switchWallet: (wallet: CardanoInitial) => Promise<string | undefined>;
  setWallet: (
    wallet: CardanoInitial
  ) => Promise<CardanoWalletExtended | undefined>;
  setAvailableWallets: (wallets: CardanoInitial[]) => void;
  adaHandle?: string;
  walletAddress?: string;
  stakeAddress?: string;
  availableWallets?: CardanoInitial[];
  adaBalance?: string;
  adaBalanceNumber?: number;
  tryLoadingAgain: () => Promise<void>;
  assets?: {
    assets: NativeAsset[];
    balance: string;
  };
  registerBalanceListener: () => () => void;
  balanceListeners: Set<() => void>;
  error?: string;
  clearError: () => void;
  loadingWalletAddress: boolean;
  triggerSignOut: () => void;
  signOutTrigger: boolean;
  clearWallet: () => void;
};

const NETWORK_TESTNET = 0;
const NETWORK_MAINNET = 1;

type PersistImpl = (
  storeInitializer: StateCreator<WalletContextProps, [], []>
) => StateCreator<
  WalletContextProps,
  [['zustand/devtools', WalletContextProps]],
  []
>;

const walletPersistanceMiddleware: PersistImpl = (fn) => (set, get, api) => {
  const selectedWallet =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('selected-wallet')
      : null;
  if (selectedWallet) {
    console.log('selected wallet', selectedWallet);
    setupWallet(selectedWallet).then((result) => {
      if (!result) {
        console.log('no result', result);
        set(
          produce((s: WalletContextProps) => {
            s.loadingWalletAddress = false;
          })
        );
        return;
      }
      if (result) {
        console.log('result', result.wallet);
        set(
          produce((s: WalletContextProps) => {
            if (result.wallet) {
              s.setWallet(result.wallet);
            } else {
              s.loadingWalletAddress = false;
            }
            s.availableWallets = result.availWallets;
          })
        );
      }
    });
  } else {
    console.log('no selected wallet', selectedWallet);

    setupWallet(null).then((result) => {
      if (!result) {
        set(
          produce((s: WalletContextProps) => {
            s.loadingWalletAddress = false;
          })
        );
        return;
      }
      if (result) {
        console.log('result', result.wallet);
        set(
          produce((s: WalletContextProps) => {
            s.loadingWalletAddress = false;
            s.availableWallets = result.availWallets;
            console.log('wallet: ', result.availWallets);
          })
        );
      }
    });
  }
  const initialState = fn(set, get, api);

  return initialState;
};

export const useWalletStore = create<
  WalletContextProps,
  [['zustand/devtools', WalletContextProps]]
>(
  devtools(
    walletPersistanceMiddleware((set, get) => ({
      connecting: false,
      signOutTrigger: false, // Initial value
      adaHandle: undefined,
      wallet: undefined,
      walletAddress: undefined,
      stakeAddress: undefined,
      balanceListeners: new Set(),
      loadingWalletAddress: true,
      triggerSignOut: () => {
        set({ signOutTrigger: true });
        setTimeout(() => {
          set({ signOutTrigger: false }); // Reset trigger after a delay
        }, 100);
      },

      registerBalanceListener: () => {
        const cleanUp = () => {
          set(
            produce((s: WalletContextProps) => {
              s.balanceListeners.delete(cleanUp);
            })
          );
        };

        set(
          produce((s: WalletContextProps) => {
            s.balanceListeners.add(cleanUp);
          })
        );

        return cleanUp;
      },

      tryLoadingAgain: async () => {
        const selectedWallet =
          typeof window !== 'undefined'
            ? window.localStorage.getItem('selected-wallet')
            : null;
          console.log('selected wallet from loadingx', selectedWallet);
          setupWallet(selectedWallet)
      },

      clearError: () => {
        set(
          produce((s) => {
            s.error = undefined;
          })
        );
      },

      clearWallet: () => {
        console.log('clearing wallet');
        set(
          produce((s) => {
            s.wallet = undefined;
            s.walletAddress = undefined;
            s.stakeAddress = undefined;
            s.adaHandle = undefined;
            s.adaBalance = undefined;
            s.adaBalanceNumber = undefined;
            s.assets = undefined;
          })
        );
      },

      setWallet: async (wallet: CardanoInitial) => {
        set(
          produce((s: WalletContextProps) => {
            s.connecting = true;
          })
        );
        try {
          const supportedWallet = SUPPORTED_WALLETS_META.find(
            (m) => wallet.internalName === m.internalName
          );
          const cardanoWallet =
            (await wallet.enable()) as CardanoWalletExtended;
          const wal: CardanoWalletExtended = {
            name: wallet.name,
            icon: wallet.icon,
            getBalance() {
              return cardanoWallet.getBalance();
            },
            getChangeAddress() {
              return cardanoWallet.getChangeAddress();
            },
            getNetworkId() {
              return cardanoWallet.getNetworkId();
            },
            getRewardAddresses() {
              return cardanoWallet.getRewardAddresses();
            },
            getUnusedAddresses() {
              return cardanoWallet.getUnusedAddresses();
            },
            getUtxos(amount, paginate) {
              return cardanoWallet.getUtxos(amount, paginate);
            },
            getUsedAddresses(paginate) {
              return cardanoWallet.getUsedAddresses(paginate);
            },
            signData(addr, sigStructure) {
              return cardanoWallet.signData(addr, sigStructure);
            },
            signTx(tx, partialSign) {
              return cardanoWallet.signTx(tx, partialSign);
            },
            submitTx(tx) {
              return cardanoWallet.submitTx(tx);
            },
            getCollateral() {
              return cardanoWallet.getCollateral
                ? cardanoWallet.getCollateral()
                : cardanoWallet.experimental?.getCollateral() ??
                    Promise.resolve(undefined);
            },
          };
          console.log('Updated Wallet', wal);
          const network = await wal.getNetworkId();
          console.log('network', constants.TESTNET, network);
          if (
            !(
              (network === NETWORK_TESTNET && constants.TESTNET) ||
              (network === NETWORK_MAINNET && !constants.TESTNET)
            )
          ) {
            set(
              produce((s: WalletContextProps) => {
                s.error = 'Selected Wallet is on incorrect Network.';
                s.loadingWalletAddress = false;
              })
            );
            return;
          }

          console.log(
            'Wallet loaded on network',
            network === NETWORK_TESTNET ? 'Testnet' : 'Mainnet'
          );

          wal.getBalance().then((balance) => {
            console.log('Got a wallet balance');
            try {
              const bytes = hexToBytes(balance);
              let value: BigInt;

              if (CborData.isList(bytes)) {
                const l = ListData.fromCbor(bytes);
                value = (l.list[0] as IntData).int;
              } else {
                value = CborData.decodeInteger(bytes);
              }
              set(
                produce((state: WalletContextProps) => {
                  state.adaBalance = Intl.NumberFormat().format(
                    Math.round(Number(value) / 10_000) / 100
                  );
                  state.adaBalanceNumber =
                    Math.round(Number(value) / 10_000) / 100;
                })
              );
            } catch (err) {
              console.error(err);
            }
          });
          (supportedWallet?.addressType === 'REWARD'
            ? wal.getRewardAddresses()
            : wal.getUsedAddresses()
          )
            .then((addr) => {
              const address =
                supportedWallet?.addressType === 'REWARD'
                  ? StakeAddress.fromHex(addr[0]).toBech32()
                  : Address.fromHex(addr[0]).toBech32();

              set(
                produce((state: WalletContextProps) => {
                  state.walletAddress = address;
                  state.loadingWalletAddress = false;
                })
              );

              wal.getRewardAddresses().then((s) => {
                const address2 = StakeAddress.fromHex(s[0]).toBech32();
                set(
                  produce((state: WalletContextProps) => {
                    state.stakeAddress = address2;
                  })
                );
                const currentUser = firebase.auth().currentUser;
                if (currentUser && currentUser.uid !== address2) {
                  useWalletStore.getState().triggerSignOut();
                }
              });

              trpcClient.query('assets', { address }).then((result) => {
                set(
                  produce((s: WalletContextProps) => {
                    s.assets = result;
                  })
                );
              });

              return getAdaHandle(address);
            })
            .then((handle) =>
              set(
                produce((s) => {
                  s.adaHandle = handle;
                })
              )
            )
            .catch((err) => {
              set(
                produce((s: WalletContextProps) => {
                  s.connecting = false;
                })
              );
              console.error(err);
            });

          set(
            produce((state: WalletContextProps) => {
              state.wallet = wal;
              state.connecting = false;
            })
          );
          return wal;
        } catch (err) {
          set(
            produce((state: WalletContextProps) => {
              state.loadingWalletAddress;
              state.connecting = false;
              state.error =
                err instanceof Error ? err.message : JSON.stringify(err);
            })
          );
        }
      },

      setAvailableWallets: (wallets: CardanoInitial[]) => {
        set(
          produce((state: WalletContextProps) => {
            state.availableWallets = wallets;
          })
        );
      },

      switchWallet: async (wallet: CardanoInitial) => {
        if (get().connecting || get().loadingWalletAddress) {
          return;
        }
        set(
          produce((s: WalletContextProps) => {
            s.connecting = true;
          })
        );
        let walletObj = {} as WalletObjProps;
        try {
          set(
            produce((s: WalletContextProps) => {
              s.loadingWalletAddress = true;
            })
          );
          const supportedWallet = SUPPORTED_WALLETS_META.find(
            (m) => wallet.internalName === m.internalName
          );
          console.log('In setWallet ', wallet.name);
          const cardanoWallet =
            (await wallet.enable()) as CardanoWalletExtended;
          console.log('Wallet is not enabled?', cardanoWallet);
          const wal: CardanoWalletExtended = {
            name: wallet.name,
            icon: wallet.icon,
            getBalance() {
              return cardanoWallet.getBalance();
            },
            getChangeAddress() {
              return cardanoWallet.getChangeAddress();
            },
            getNetworkId() {
              return cardanoWallet.getNetworkId();
            },
            getRewardAddresses() {
              return cardanoWallet.getRewardAddresses();
            },
            getUnusedAddresses() {
              return cardanoWallet.getUnusedAddresses();
            },
            getUtxos(amount, paginate) {
              return cardanoWallet.getUtxos(amount, paginate);
            },
            getUsedAddresses(paginate) {
              return cardanoWallet.getUsedAddresses(paginate);
            },
            signData(addr, sigStructure) {
              return cardanoWallet.signData(addr, sigStructure);
            },
            signTx(tx, partialSign) {
              return cardanoWallet.signTx(tx, partialSign);
            },
            submitTx(tx) {
              return cardanoWallet.submitTx(tx);
            },
            getCollateral() {
              return cardanoWallet.getCollateral
                ? cardanoWallet.getCollateral()
                : cardanoWallet.experimental?.getCollateral() ??
                    Promise.resolve(undefined);
            },
          };
          console.log('Updated Wallet', wal);
          const network = await wal.getNetworkId();

          if (
            !(
              (network === NETWORK_TESTNET && constants.TESTNET) ||
              (network === NETWORK_MAINNET && !constants.TESTNET)
            )
          ) {
            set(
              produce((s: WalletContextProps) => {
                s.error = 'Selected Wallet is on incorrect Network.';
                s.loadingWalletAddress = false;
                s.connecting = false;
              })
            );
            return;
          }

          console.log(
            'Wallet loaded on network',
            network === NETWORK_TESTNET ? 'Testnet' : 'Mainnet'
          );

          wal.getBalance().then((balance) => {
            console.log('Got a wallet balance');
            try {
              const bytes = hexToBytes(balance);
              let value: BigInt;

              if (CborData.isList(bytes)) {
                const l = ListData.fromCbor(bytes);
                value = (l.list[0] as IntData).int;
              } else {
                value = CborData.decodeInteger(bytes);
              }
              // set(
              //   produce((state: WalletContextProps) => {
              //     state.adaBalance = Intl.NumberFormat().format(
              //       Math.round(Number(value) / 10_000) / 100
              //     );
              //     state.adaBalanceNumber =
              //       Math.round(Number(value) / 10_000) / 100;
              //   })
              // );
              walletObj = {
                ...walletObj,
                adaBalance: Intl.NumberFormat().format(
                  Math.round(Number(value) / 10_000) / 100
                ),
                adaBalanceNumber: Math.round(Number(value) / 10_000) / 100,
              };
            } catch (err) {
              console.error(err);
            }
          });
          (supportedWallet?.addressType === 'REWARD'
            ? wal.getRewardAddresses()
            : wal.getUsedAddresses()
          )
            .then((addr) => {
              const address =
                supportedWallet?.addressType === 'REWARD'
                  ? StakeAddress.fromHex(addr[0]).toBech32()
                  : Address.fromHex(addr[0]).toBech32();

              // set(
              //   produce((state: WalletContextProps) => {
              //     state.walletAddress = address;
              //     state.loadingWalletAddress = false;
              //   })
              // );
              walletObj = { ...walletObj, walletAddress: address };

              wal.getRewardAddresses().then((s) => {
                const stake = StakeAddress.fromHex(s[0]).toBech32();
                walletObj = { ...walletObj, stakeAddress: stake };
              });

              trpcClient.query('assets', { address }).then((result) => {
                // set(
                //   produce((s: WalletContextProps) => {
                //     s.assets = result;
                //   })
                // );
                walletObj = { ...walletObj, assets: result };
              });

              return getAdaHandle(address);
            })
            .then(
              (handle) =>
                // set(
                //   produce((s) => {
                //     s.adaHandle = handle;
                //   })
                // )
                !!handle && (walletObj = { ...walletObj, adaHandle: handle })
            )
            .catch(console.error);

          // set(
          //   produce((state: WalletContextProps) => {
          //     state.wallet = wal;
          //   })
          // );
          walletObj = { ...walletObj, wallet: wal };

          try {
            const token = await getLoginToken(wal);

            if (!token) {
              throw new Error('Unable to get Web3 Token');
            }

            const result = await fetch(getAbsoluteURL('/api/web3Login'), {
              headers: {
                authorization: `web3 ${token}`,
              },
              credentials: 'include',
            });

            if (!result.ok) {
              console.log(await result.json());
              throw new Error('Unauthenticated');
            }

            const firebaseToken = (await result.json()).token;

            const userCred = await Firebase.auth().signInWithCustomToken(
              firebaseToken
            );

            window.localStorage.getItem('selected-wallet') !== wal.name &&
              window.localStorage.setItem('selected-wallet', wal.name);

            await userCred.user?.getIdToken();
            console.log('loggedin', walletObj);
            set(
              produce((state: WalletContextProps) => {
                state.wallet = wal;
                state.adaBalance = walletObj.adaBalance;
                state.adaBalanceNumber = walletObj.adaBalanceNumber;
                state.walletAddress = walletObj.walletAddress;
                state.stakeAddress = walletObj.stakeAddress;
                state.adaHandle = walletObj.adaHandle;
                state.assets = walletObj.assets;
                state.loadingWalletAddress = false;
              })
            );
            return 'success';
          } catch (err) {
            set(
              produce((s: WalletContextProps) => {
                s.loadingWalletAddress = false;
                s.connecting = false;
              })
            );
            throw err;
          } finally {
            console.log('loggedin', walletObj);
            set(
              produce((s: WalletContextProps) => {
                s.loadingWalletAddress = false;
                s.connecting = false;
              })
            );
          }
        } catch (err) {
          set(
            produce((state: WalletContextProps) => {
              state.error =
                err instanceof Error ? err.message : JSON.stringify(err);
              state.loadingWalletAddress = false;
              state.connecting = false;
            })
          );
        }
      },
    })),
    {
      enabled: constants.TESTNET,
    }
  )
);

BalanceListener.getInstance();

function isWalletEnabled(wallet: CardanoInitial) {
  return new Promise((resolve) => {
    const to = setTimeout(() => resolve(false), 1000);
    wallet
      .isEnabled()
      .then((r) => {
        clearTimeout(to);
        resolve(r);
      })
      .catch(console.error);
  });
}

async function setupWallet(name?: string | null | undefined, attempt = 0) {
  if (typeof window === 'undefined') {
    console.log('window is undefined');
    return;
  }
  if (!window.cardano) {
    if (attempt < 11) {
      setTimeout(() => {
        setupWallet(name, ++attempt);
        console.log('attempt', attempt)
      }, 500);
      return;
    }
    if (attempt > 10) {
      useWalletStore.getState().triggerSignOut();
      console.log('attempt', attempt)
    }
  }
  const availWallets: CardanoInitial[] = [];
  const enabledWallets: CardanoInitial[] = []; // New array to hold all enabled wallets
  let wallet: CardanoInitial | undefined = undefined;

  for (const w of SUPPORTED_WALLETS) {
    if (window.cardano && window.cardano[w]) {
      const x = { ...window.cardano[w], internalName: w };
      availWallets.push(x);
      console.log('new supported wallet found', x.name, x.internalName);
      // console.log(x);
      const enabled = await isWalletEnabled(window.cardano[w]);
      // console.log('is enabled: ', enabled);
      if (enabled) {
        enabledWallets.push(x); // Collect all enabled wallets
        try {
          // console.log('x: ', x, 'selected: ', name);
          if (x.name === name) {
            wallet = x;
            useWalletStore.getState().setWallet(x);
          }
        } catch (_) {
          console.log('error in setupWallet');
        }
      }
    }
  }
 console.log('wallets result', availWallets, wallet);

 if (availWallets.length > 0)  {
  useWalletStore.getState().setAvailableWallets(availWallets);
  }

  return { availWallets, wallet };

}

async function getAdaHandle(walletAddress: string) {
  // Fetch new API
  //now i have the address.

  try {
    console.log(`/api/blockfrost/adahandle/${walletAddress}`);
    const result = await fetch(
      getAbsoluteURL(`/api/blockfrost/adahandle/${walletAddress}`),
      {
        headers: {},
      }
    );
    // setLoading(false);
    if (result.status !== 200) {
      console.error('Error', result.status, await result.text());
      throw new Error('Unable to retrieve ADA Handle');
    }

    const data: NativeAsset[] = (await result.json()) as NativeAsset[];

    return data[0].onchain_metadata?.name as string;

    // setNativeAssets(data);
  } catch (err) {
    //  setLoading(false);
  }
}

export type CardanoWalletExtended = Cardano & { name: string; icon: string };

const getLoginToken = async (wallet: CardanoWalletExtended) => {
  const address = await wallet.getRewardAddresses();

  if (!address || address.length === 0) {
    throw new Error('No wallet address');
  }

  return await Web3Token.sign(async (msg) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return await wallet!.signData(address[0], Buffer.from(msg).toString('hex'));
  });
};

function walletStoreSelector(s: WalletContextProps) {
  return {
    switchWallet: s.switchWallet,
    setWallet: s.setWallet,
    setAvailableWallets: s.setAvailableWallets,
    adaHandle: s.adaHandle,
    wallet: s.wallet,
    walletAddress: s.walletAddress,
    stakeAddress: s.stakeAddress,
    availableWallets: s.availableWallets,
    adaBalance: s.adaBalance,
    adaBalanceNumber: s.adaBalanceNumber,
    assets: s.assets,
    loadingWalletAddress: s.loadingWalletAddress,
    signOutTrigger: s.signOutTrigger,
    tryLoadingAgain: s.tryLoadingAgain,
    registerBalanceListener: s.registerBalanceListener,
    clearWallet: s.clearWallet,
  };
}

export const useCardanoWallet = () => {
  const {
    switchWallet,
    setWallet,
    setAvailableWallets,
    adaHandle,
    wallet,
    walletAddress,
    stakeAddress,
    availableWallets,
    adaBalance,
    adaBalanceNumber,
    assets,
    loadingWalletAddress,
    tryLoadingAgain,
    registerBalanceListener,
    clearWallet,
  } = useWalletStore(walletStoreSelector);

  const [waitingForWalletLogin, setWaitingForWalletLogin] = useState(false);

  const login = async (lWallet: CardanoWalletExtended) => {
    if (!lWallet) return;
    setWaitingForWalletLogin(true);

    try {
      const token = await getLoginToken(lWallet);

      if (!token) {
        clearWallet();
        throw new Error('Unable to get Web3 Token');
      }

      const result = await fetch(getAbsoluteURL('/api/web3Login'), {
        headers: {
          authorization: `web3 ${token}`,
        },
        credentials: 'include',
      });

      if (!result.ok) {
        console.log(await result.json());
        clearWallet();
        throw new Error('Unauthenticated');
      }

      const firebaseToken = (await result.json()).token;

      const userCred = await Firebase.auth().signInWithCustomToken(
        firebaseToken
      );

      window.localStorage.getItem('selected-wallet') !== lWallet.name &&
        window.localStorage.setItem('selected-wallet', lWallet.name);

      await userCred.user?.getIdToken();
    } catch (err) {
      clearWallet();
      throw err;
    } finally {
      setWaitingForWalletLogin(false);
    }
  };

  return {
    wallet,
    switchWallet,
    setWallet,
    setAvailableWallets,
    availableWallets,
    login,
    waitingForWalletLogin,
    adaHandle,
    walletAddress,
    stakeAddress,
    adaBalance,
    adaBalanceNumber,
    assets,
    loadingWalletAddress,
    tryLoadingAgain,
    registerBalanceListener,
    clearWallet,
  };
};
